import moment from "moment";
export default {
  props: {
    cliente: Object,
  },
  data() {
    return {
      menu: false,
      dialog_elimina: false,
      dialog_errore_elimina: false,
      dialog_conferma_invio: false,
      loading_invio: false,
    };
  },

  computed: {
    stato() {
      switch (this.cliente.stato) {
        case 0:
          return {
            text: "In Attesa",
            icon: "mdi-account-clock",
            color: "orange",
          };
        case 1:
          return {
            text: "Attivo",
            icon: "mdi-check-circle",
            color: "green",
          };
        case 2:
          return {
            text: "Bloccato",
            icon: "mdi-account-cancel",
            color: "red",
          };
      }
      return null;
    },
    ultimo_accesso() {
      if (!this.cliente.last_access) {
        return "Mai";
      }
      if (this.cliente.is_connected) {
        return "Online";
      }
      return moment.unix(this.cliente.last_access).format("ddd DD/MM HH:mm");
    },
  },
  methods: {
    cambia_stato(status) {
      this.$store.dispatch("status_cliente", {
        iva: this.cliente.iva,
        status: status,
      });
    },
    attivazione_cliente() {
      this.loading_invio = true;
      this.$store
        .dispatch("attivazione_cliente", { iva: this.cliente.iva })
        .then(() => {
          this.loading_invio = false;
          this.dialog_conferma_invio = false;
        });
    },
    elimina_cliente() {
      this.$store
        .dispatch("elimina_cliente", { iva: this.cliente.iva })
        .catch(() => {
          this.dialog_errore_elimina = true;
        });
    },
  },
};
