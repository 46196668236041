var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"py-2",class:hover ? 'green lighten-5' : 'white',attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-truncate pr-4",attrs:{"cols":"3"}},[_c('v-badge',{staticClass:"mr-4",attrs:{"value":_vm.cliente.is_connected,"color":"green","dot":"","offset-x":"5","offset-y":"10"}},[_c('v-icon',[_vm._v("mdi-account")])],1),(_vm.cliente.numero)?_c('span',[_vm._v(_vm._s(_vm.cliente.numero)+" - ")]):_vm._e(),_c('b',[_vm._v(_vm._s(_vm.cliente.nome))])],1),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.cliente.iva)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.cliente.email)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.cliente.telefono)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.cliente.listino)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":_vm.stato.color}},[_vm._v(_vm._s(_vm.stato.icon))])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":_vm.cliente.no_subscription ? 'amber' : 'blue'}},[_vm._v(_vm._s(_vm.cliente.no_subscription ? "mdi-bell-off" : "mdi-bell"))]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":_vm.cliente.notif_chat ? 'green' : 'amber'}},[_vm._v(_vm._s(_vm.cliente.notif_chat ? "mdi-message-check-outline" : "mdi-comment-remove-outline"))]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":_vm.cliente.notif_confirm ? 'purple' : 'amber'}},[_vm._v(_vm._s(_vm.cliente.notif_confirm ? "mdi-file-document-check-outline" : "mdi-file-document-remove-outline"))])],1),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.ultimo_accesso)+" ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.cliente.nome))]),_c('v-list-item-subtitle',[_vm._v(" Stato: "),_c('span',{class:_vm.stato.color + '--text'},[_vm._v(_vm._s(_vm.stato.text))])])],1)],1),_c('v-divider'),(_vm.cliente.stato == 1)?_c('v-list-item',{on:{"click":function($event){return _vm.cambia_stato(2)}}},[_c('v-list-item-content',[_vm._v(" Blocca ")]),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-account-lock")])],1)],1):_vm._e(),(_vm.cliente.stato == 2)?_c('v-list-item',{on:{"click":function($event){return _vm.cambia_stato(1)}}},[_c('v-list-item-content',[_vm._v(" Sblocca ")]),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account-lock-open-outline")])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.cliente.stato == 0 ? "Attiva" : "Reinvia Invito")+" ")]),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(_vm._s(_vm.cliente.stato == 0 ? "mdi-send" : "mdi-refresh"))])],1)],1)]}}],null,true),model:{value:(_vm.dialog_conferma_invio),callback:function ($$v) {_vm.dialog_conferma_invio=$$v},expression:"dialog_conferma_invio"}},[_c('v-card',[_c('v-card-title',{staticClass:"warning--text"},[_vm._v(" Attenzione ")]),_c('v-card-text',[_vm._v(" Un nuovo invito verrà invito per e-mail. "),_c('br'),_vm._v(" Procedere? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog_conferma_invio = false}}},[_vm._v("annulla")]),_c('v-btn',{attrs:{"outlined":"","color":"green","loading":_vm.loading_invio},on:{"click":function($event){return _vm.attivazione_cliente()}}},[_vm._v("ok")])],1)],1)],1),_c('ModificaUtente',{attrs:{"cliente":_vm.cliente}}),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-content',[_vm._v(" Elimina Utente ")]),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true),model:{value:(_vm.dialog_elimina),callback:function ($$v) {_vm.dialog_elimina=$$v},expression:"dialog_elimina"}},[_c('v-card',[_c('v-card-title',{staticClass:"red--text"},[_vm._v(" Elimina Cliente ")]),_c('v-card-text',[_vm._v(" Sei sicuro? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.elimina_cliente()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete")]),_vm._v(" elimina ")],1),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog_elimina = false}}},[_vm._v("annulla")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialog_errore_elimina),callback:function ($$v) {_vm.dialog_errore_elimina=$$v},expression:"dialog_errore_elimina"}},[_c('v-card',[_c('v-card-title',{staticClass:"red--text"},[_vm._v(" Errore ")]),_c('v-card-text',[_vm._v(" Impossibile eliminare. "),_c('br'),_vm._v(" Ci sono degli ordini collegati al cliente ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":function($event){_vm.dialog_errore_elimina = false}}},[_vm._v("Fatto")])],1)],1)],1)],1)],1)],1)],1)]}}])}),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }