<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on }">
      <v-btn fab fixed bottom right color="green" v-on="on" class="ma-6">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="grey--text text-subtitle-1">
        <v-toolbar-title>Nuovo Cliente</v-toolbar-title>
      </v-toolbar>
      <v-tabs>
        <v-tab>
          <v-icon left> mdi-account-plus </v-icon>
          Carica Singolo
        </v-tab>
        <v-tab>
          <v-icon left> mdi-account-multiple-plus </v-icon>
          Carica CSV
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-form v-model="valid" ref="form">
                <v-row>
                  <v-col cols="12">
                    <v-switch
                      class="ml-2"
                      inset
                      v-model="cliente.isAdmin"
                      label="Utente ADMIN"
                    ></v-switch> {{cliente.isAdmin}}
                  </v-col>
                  <template v-if="!cliente.isAdmin">
                    <v-col cols="12">
                      <v-text-field
                        label="Nominativo"
                        outlined
                        v-model="cliente.nome"
                        prepend-icon="mdi-account"
                        :rules="rule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Indirizzo"
                        outlined
                        v-model="cliente.indirizzo"
                        prepend-icon="mdi-map-marker"
                        :rules="rule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Città"
                        outlined
                        v-model="cliente.città"
                        prepend-icon="mdi-city"
                        :rules="rule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Telefono"
                        outlined
                        v-model="cliente.telefono"
                        prepend-icon="mdi-phone"
                        :rules="rule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="E-Mail"
                        outlined
                        v-model="cliente.email"
                        prepend-icon="mdi-mail"
                        :rules="rule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        label="P. IVA"
                        outlined
                        v-model="cliente.iva"
                        prepend-icon="mdi-cash"
                        :rules="rule_iva"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        label="Listino"
                        outlined
                        :items="listino"
                        v-model="cliente.listino"
                        prepend-icon="mdi-account-cash-outline"
                        :rules="rule"
                      ></v-select>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col cols="8">
                      <v-text-field
                        label="Login Name"
                        outlined
                        v-model="cliente.iva"
                        prepend-icon="mdi-key"
                        :rules="rule_iva"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Nominativo"
                        outlined
                        v-model="cliente.nome"
                        prepend-icon="mdi-account"
                        :rules="rule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="E-Mail"
                        outlined
                        v-model="cliente.email"
                        prepend-icon="mdi-mail"
                        :rules="rule"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="green" dark @click="invia()"> Fatto </v-btn>
              <v-btn color="green" text @click="close()"> Annulla </v-btn>
              <v-btn color="error" text @click="reset()"> Reset </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-card flat>
                <v-card-title> Upload Clienti CSV </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <b>Campi richiesti:</b> <br /><br />
                      Nome<br />
                      Partita Iva<br />
                      E-mail<br />
                      Città<br />
                      Indirizzo<br />
                      Telefono<br />
                      Listino
                    </v-col>
                  </v-row>
                  <v-file-input v-model="clienti_csv"></v-file-input>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text color="primary" @click="close()"> Annulla </v-btn>
                  <v-btn @click="carica_csv()" color="primary"> Carica </v-btn>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      clienti_csv: [],
      dialog: false,
      valid: true,
      iva_ok: true,
      cliente: {
        isAdmin: false,
        nome: "",
        indirizzo: "",
        città: "",
        telefono: "",
        email: "",
        iva: "",
        listino: "",
        stato: 0,
      },
      listino: ["1", "2", "3"],
      rule: [(v) => !!v || "Inserire Valore"],
      rule_iva: [
        (v) => !!v || "Inserire Valore",
        () => this.iva_ok || "Cliente Già Presente ",
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    invia() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.$store
        .dispatch("nuovo_cliente", this.cliente)
        .then((res) => {
          if (!res) {
            this.iva_ok = false;
            this.$refs.form.validate();
            return;
          }
          this.$refs.form.reset();
          this.dialog = false;
          this.$emit("close");
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$router.push("/login");
          }
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    carica_csv() {
      if (typeof this.clienti_csv.name == "string") {
        let formData = new FormData();
        formData.append("file", this.clienti_csv);
        this.$store
          .dispatch("carica_csv_clienti", formData)
          .then(() => {})
          .catch((err) => {
            if (err.status == 400) {
              this.$route.push("/login");
            }
          });
      } else {
        alert("errore");
      }
    },
  },
};
</script>