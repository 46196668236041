import _ from "lodash";
export default {
  props: {
    cliente: Object,
  },
  created() {
    this.debounceEmail = _.debounce(this.set_email, 2000);
    this.debounceNome = _.debounce(this.set_nome, 2000);
    this.debounceAka = _.debounce(this.set_aka, 2000);
    this.debounceTel = _.debounce(this.set_tel, 2000);
    this.debounceIndirizzo = _.debounce(this.set_indirizzo, 2000);
    this.debounceCittà = _.debounce(this.set_città, 2000);
    this.debounceNumero = _.debounce(this.set_numero, 2000);
  },
  data() {
    return {
      dialog: false,

      nome: this.cliente.nome,
      aka: this.cliente.aka,
      email: this.cliente.email,
      telefono: this.cliente.telefono,
      indirizzo: this.cliente.indirizzo,
      città: this.cliente.città,
      numero: this.cliente.numero,
      listino: this.cliente.listino,
      cambio_carrello: this.cliente.cambio_cc,
      tipo_carrello: this.cliente.tipo_cc,
      zona_consegna: this.cliente.zona,
      loading_nome: false,
      ok_nome: false,
      fail_nome: false,
      loading_aka: false,
      ok_aka: false,
      fail_aka: false,
      loading_mail: false,
      ok_mail: false,
      fail_mail: false,
      loading_tel: false,
      ok_tel: false,
      fail_tel: false,
      loading_indirizzo: false,
      ok_indirizzo: false,
      fail_indirizzo: false,
      loading_città: false,
      ok_città: false,
      fail_città: false,
      loading_listino: false,
      ok_listino: false,
      fail_listino: false,
      loading_cambio: false,
      loading_tipo_cc: false,
      ok_tipo_cc: false,
      fail_tipo_cc: false,
      loading_zona: false,
      ok_zona: false,
      fail_zona: false,
      loading_numero: false,
      ok_numero: false,
      fail_numero: false,
      dialog_add_dest: false,
      nuova_dest: "",
      dialog_edit_pluscode: false,
      plus_code: this.cliente.plus_code,
      loading_plus: false,
      rules: {
        required: (value) => !!value || "Campo Richiestro",
      },
      new_dest_valid: false,
      lista_tipo_cc: [
        { value: 0, text: "Non definito" },
        { value: 1, text: "Rosso Nostro" },
        { value: 2, text: "Lucchetto Nero" },
        { value: 3, text: "Tag Verde" },
      ],
    };
  },
  watch: {
    nome: function () {
      this.debounceNome();
    },
    aka: function () {
      this.debounceAka();
    },
    email: function () {
      this.debounceEmail();
    },
    telefono: function () {
      this.debounceTel();
    },
    indirizzo: function () {
      this.debounceIndirizzo();
    },
    città: function () {
      this.debounceCittà();
    },
    listino: function () {
      this.set_listino();
    },
    zona_consegna: function () {
      this.set_zona();
    },
    cambio_carrello: function () {
      this.loading_cambio = true;
      this.$store
        .dispatch("edit_cambio_cc", {
          iva: this.cliente.iva,
          cambio: this.cambio_carrello,
        })
        .finally(() => (this.loading_cambio = false));
    },
    tipo_carrello: function () {
      this.loading_tipo_cc = true;
      this.$store
        .dispatch("edit_tipo_cc", {
          iva: this.cliente.iva,
          tipo: this.tipo_carrello,
        })
        .finally(() => (this.loading_tipo_cc = false));
    },
    numero: function () {
      this.debounceNumero();
    },
  },
  computed: {
    zone() {
      return this.$store.getters.get_zone;
    },
    destinazioni() {
      return this.cliente.destinazioni;
    },
  },
  methods: {
    set_nome() {
      this.loading_nome = true;
      this.$store
        .dispatch("edit_nome", { iva: this.cliente.iva, nome: this.nome })
        .then(() => {
          this.loading_nome = false;
          this.ok_nome = true;
          setTimeout(() => {
            this.ok_nome = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_nome = false;
          this.fail_nome = true;
          setTimeout(() => {
            this.fail_nome = false;
          }, 2000);
        });
    },
    set_aka() {
      this.loading_aka = true;
      this.$store
        .dispatch("edit_aka", { iva: this.cliente.iva, aka: this.aka })
        .then(() => {
          this.loading_aka = false;
          this.ok_aka = true;
          setTimeout(() => {
            this.ok_aka = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_aka = false;
          this.fail_aka = true;
          setTimeout(() => {
            this.fail_aka = false;
          }, 2000);
        });
    },
    set_email() {
      this.loading_mail = true;
      this.$store
        .dispatch("edit_email", { iva: this.cliente.iva, email: this.email })
        .then(() => {
          this.loading_mail = false;
          this.ok_mail = true;
          setTimeout(() => {
            this.ok_mail = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_mail = false;
          this.fail_mail = true;
          setTimeout(() => {
            this.fail_mail = false;
          }, 2000);
        });
    },
    set_tel() {
      this.loading_tel = true;
      this.$store
        .dispatch("edit_tel", { iva: this.cliente.iva, tel: this.telefono })
        .then(() => {
          this.loading_tel = false;
          this.ok_tel = true;
          setTimeout(() => {
            this.ok_tel = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_tel = false;
          this.fail_tel = true;
          setTimeout(() => {
            this.fail_tel = false;
          }, 2000);
        });
    },
    set_indirizzo() {
      this.loading_indirizzo = true;
      this.$store
        .dispatch("edit_indirizzo", {
          iva: this.cliente.iva,
          indirizzo: this.indirizzo,
        })
        .then(() => {
          this.loading_indirizzo = false;
          this.ok_indirizzo = true;
          setTimeout(() => {
            this.ok_indirizzo = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_indirizzo = false;
          this.fail_indirizzo = true;
          setTimeout(() => {
            this.fail_indirizzo = false;
          }, 2000);
        });
    },
    set_città() {
      this.loading_città = true;
      this.$store
        .dispatch("edit_città", { iva: this.cliente.iva, città: this.città })
        .then(() => {
          this.loading_città = false;
          this.ok_città = true;
          setTimeout(() => {
            this.ok_città = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_città = false;
          this.fail_città = true;
          setTimeout(() => {
            this.fail_città = false;
          }, 2000);
        });
    },
    set_listino() {
      this.loading_listino = true;
      this.$store
        .dispatch("edit_listino", {
          iva: this.cliente.iva,
          listino: this.listino,
        })
        .then(() => {
          this.loading_listino = false;
          this.ok_listino = true;
          setTimeout(() => {
            this.ok_listino = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_listino = false;
          this.fail_listino = true;
          setTimeout(() => {
            this.fail_listino = false;
          }, 2000);
        });
    },
    set_zona() {
      this.loading_zona = true;
      this.$store
        .dispatch("edit_zona", {
          iva: this.cliente.iva,
          zona: this.zona_consegna,
        })
        .then(() => {
          this.loading_zona = false;
          this.ok_zona = true;
          setTimeout(() => {
            this.ok_zona = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_zona = false;
          this.fail_zona = true;
          setTimeout(() => {
            this.fail_zona = false;
          }, 2000);
        });
    },
    set_numero() {
      this.loading_numero = true;
      this.$store
        .dispatch("edit_numero", {
          iva: this.cliente.iva,
          numero: this.numero,
        })
        .then(() => {
          this.loading_numero = false;
          this.ok_numero = true;
          setTimeout(() => {
            this.ok_numero = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_numero = false;
          this.fail_numero = true;
          setTimeout(() => {
            this.fail_numero = false;
          }, 2000);
        });
    },
    add_dest() {
      this.$refs.new_dest_form.validate();
      if (!this.new_dest_valid) {
        return;
      }
      this.$store
        .dispatch("aggiungi_nuova_dest", {
          iva: this.cliente.iva,
          destinazione: this.nuova_dest,
          plus_code: this.plus_code,
        })
        .finally(() => {
          this.dialog_add_dest = false;
        });
    },
    del_dest: function (dest) {
      this.$store.dispatch("rimuovi_dest", {
        iva: this.cliente.iva,
        destinazione: dest,
      });
    },
    edit_plus_code() {
      this.loading_plus = true;
      this.$store
        .dispatch("edit_plus_code", {
          iva: this.cliente.iva,
          plus_code: this.plus_code,
        })
        .finally(() => {
          this.loading_plus = false;
          this.dialog_edit_pluscode = false
        });
    },
  },
};
