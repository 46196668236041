<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          {{ cliente.nome }}
        </v-list-item-title>
        <v-list-item-subtitle>
          Numero: {{ cliente.numero }}
        </v-list-item-subtitle>
        <v-list-item-subtitle> P.iva: {{ cliente.iva }} </v-list-item-subtitle>
        <v-list-item-subtitle>
          Listino: {{ cliente.listino }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Stato:
          <v-icon :color="stato.color" size="15">{{ stato.icon }}</v-icon>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Ultimo Accesso: {{ultimo_accesso}}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-menu v-model="menu">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ cliente.nome }}</v-list-item-title>
                <v-list-item-subtitle>
                  Stato:
                  <span :class="stato.color + '--text'">{{ stato.text }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <v-list-item v-if="cliente.stato == 1" @click="cambia_stato(2)">
              <v-list-item-content> Blocca </v-list-item-content>
              <v-list-item-action>
                <v-icon color="red">mdi-account-lock</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="cliente.stato == 2" @click="cambia_stato(1)">
              <v-list-item-content> Sblocca </v-list-item-content>
              <v-list-item-action>
                <v-icon color="green">mdi-account-lock-open-outline</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-dialog v-model="dialog_conferma_invio" width="600">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-content>
                      {{ cliente.stato == 0 ? "Attiva" : "Reinvia Invito" }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="green">{{
                        cliente.stato == 0 ? "mdi-send" : "mdi-refresh"
                      }}</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-card>
                  <v-card-title class="warning--text">
                    Attenzione
                  </v-card-title>
                  <v-card-text>
                    Un nuovo invito verrà invito per e-mail.
                    <br />
                    Procedere?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      text
                      color="grey"
                      @click="dialog_conferma_invio = false"
                      >annulla</v-btn
                    >
                    <v-btn outlined color="green" @click="attivazione_cliente()"
                      >ok</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

            <v-list-item @click="$refs.modifica_utente.open()">
              <v-list-item-content>
                <v-list-item-title> Modifica </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-dialog v-model="dialog_elimina" width="400">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-content> Elimina Utente </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title class="red--text"> Elimina Cliente </v-card-title>
                <v-card-text> Sei sicuro? </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="red" outlined @click="elimina_cliente()">
                    <v-icon class="mr-2">mdi-delete</v-icon>
                    elimina
                  </v-btn>
                  <v-btn text color="grey" @click="dialog_elimina = false"
                    >annulla</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_errore_elimina" width="400">
              <v-card>
                <v-card-title class="red--text"> Errore </v-card-title>
                <v-card-text>
                  Impossibile eliminare.
                  <br />
                  Ci sono degli ordini collegati al cliente
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    outlined
                    color="grey"
                    @click="dialog_errore_elimina = false"
                    >Fatto</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list>
        </v-menu>
      </v-list-item-action>
      <ModificaUtente ref="modifica_utente" :cliente="cliente"></ModificaUtente>
    </v-list-item>
    <v-divider />
  </div>
</template>

<script>
import utente_element_mixin from "../mixin/utente_element_mixin";
import ModificaUtente from "./modifica_utente_mobile.vue";
export default {
  components: { ModificaUtente },
  mixins: [utente_element_mixin],
};
</script>