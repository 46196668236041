export default {
  data() {
    return {
      sort_by_name: true,
      sort_by_listino: false,
      sort_by_stato: false,
      search_string: "",
      tab: null
    };
  },
  computed: {
    clienti() {
      if (this.sort_by_name) {
        return this.$store.getters.get_clienti
          .filter((cli) => !cli.isAdmin)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } else if (this.sort_by_listino) {
        return this.$store.getters.get_clienti
          .filter((cli) => !cli.isAdmin)
          .sort((a, b) => b.listino - a.listino);
      } else if (this.sort_by_stato) {
        return this.$store.getters.get_clienti
          .filter((cli) => !cli.isAdmin)
          .sort((a, b) => b.stato - a.stato);
      }
    },
    clienti_filtered() {
      if (this.search_string.length < 3) {
        return this.clienti;
      }
      let regex = new RegExp(this.search_string, "i");
      return this.clienti.filter((cliente) => {
        return regex.test(cliente.nome);
      });
    },
    admins() {
      return this.$store.getters.get_clienti
        .filter((cli) => cli.isAdmin)
        .sort((a, b) => a.nome.localeCompare(b.nome));
    },
  },
  methods: {},
};
