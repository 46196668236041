<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-row
        align="center"
        class="py-2"
        no-gutters
        :class="hover ? 'green lighten-5' : 'white'"
      >
        <v-col cols="2" class="text-truncate pr-4">
          <v-badge
            :value="cliente.is_connected"
            color="green"
            dot
            offset-x="5"
            offset-y="10"
            class="mr-4"
          >
            <v-icon >mdi-account</v-icon>
          </v-badge>
          <span v-if="cliente.numero">{{ cliente.numero }} - </span>
          <b>{{ cliente.nome }}</b>
        </v-col>
        <v-col cols="2" class=""> {{ cliente.iva }} </v-col>
        <v-col cols="2" class=""> {{ cliente.email }} </v-col>
        <v-col cols="1" class=""> {{ cliente.telefono }} </v-col>
        
        <v-col cols="1" class="">
          <v-icon :color="cliente.no_subscription ? 'amber' : 'blue'">{{cliente.no_subscription ? 'mdi-bell-off' : 'mdi-bell'}}</v-icon>
          <v-icon :color="cliente.notif_chat ? 'green' : 'amber'" class="ml-2">{{cliente.notif_chat ? 'mdi-message-check-outline' : 'mdi-comment-remove-outline'}}</v-icon>
          <v-icon :color="cliente.notif_confirm ? 'purple' : 'amber'" class="ml-2">{{cliente.notif_confirm ? 'mdi-file-document-check-outline' : 'mdi-file-document-remove-outline'}}</v-icon>
        
        
        </v-col>
        <v-col cols="1" class="">
            {{ultimo_accesso}}
        </v-col>
        <v-col cols="1" class="d-flex justify-center">
          <v-menu v-model="menu" left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ cliente.nome }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />

              <v-dialog v-model="dialog_elimina" width="400">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-content> Elimina Utente </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-card>
                  <v-card-title class="red--text">
                    Elimina Cliente
                  </v-card-title>
                  <v-card-text> Sei sicuro? </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="red" outlined @click="elimina_cliente()">
                      <v-icon class="mr-2">mdi-delete</v-icon>
                      elimina
                    </v-btn>
                    <v-btn text color="grey" @click="dialog_elimina = false"
                      >annulla</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialog_errore_elimina" width="400">
                <v-card>
                  <v-card-title class="red--text"> Errore </v-card-title>
                  <v-card-text>
                    Impossibile eliminare.
                    <br />
                    Ci sono degli ordini collegati al cliente
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      outlined
                      color="grey"
                      @click="dialog_errore_elimina = false"
                      >Fatto</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-hover>
    <v-divider />
  </div>
</template>

<script>
import utente_element_mixin from "../mixin/utente_element_mixin";
export default {
  components: {
  },
  mixins: [utente_element_mixin],
};
</script>