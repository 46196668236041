var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"grey--text text-subtitle-1"},[_vm._v(" Utenti ")]),_c('v-card-text',[_c('v-tabs',[_c('v-tab',[_vm._v(" Clienti ")]),_c('v-tab',[_vm._v(" Admins")]),_c('v-tab-item',[_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","label":"Cerca ..."},model:{value:(_vm.search_string),callback:function ($$v) {_vm.search_string=$$v},expression:"search_string"}})],1)],1),_c('v-row',{staticClass:"my-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"3"},on:{"click":function($event){_vm.sort_by_stato = false;
                  _vm.sort_by_listino = false;
                  _vm.sort_by_name = true;}}},[_vm._v(" Nominativo "),(_vm.sort_by_name)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_vm._e()],1),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"}},[_vm._v(" P. Iva ")]),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"2"}},[_vm._v(" Email ")]),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"}},[_vm._v(" Telefono ")]),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"},on:{"click":function($event){_vm.sort_by_name = false;
                  _vm.sort_by_stato = false;
                  _vm.sort_by_listino = true;}}},[_vm._v(" Listino "),(_vm.sort_by_listino)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_vm._e()],1),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"},on:{"click":function($event){_vm.sort_by_name = false;
                  _vm.sort_by_listino = false;
                  _vm.sort_by_stato = true;}}},[_vm._v(" Stato "),(_vm.sort_by_stato)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_vm._e()],1),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"}},[_vm._v(" Notifiche ")]),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"}},[_vm._v(" Ultimo accesso ")])],1),_c('v-divider'),_vm._l((_vm.clienti_filtered),function(cliente){return [_c('v-lazy',{key:cliente.iva,attrs:{"options":{
                  threshold: 0.5,
                },"min-height":"20"}},[_c('ClienteElement',{attrs:{"cliente":cliente}})],1)]})],2),_c('v-tab-item',[_c('v-row',{staticClass:"my-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"2"},on:{"click":function($event){_vm.sort_by_stato = false;
                  _vm.sort_by_listino = false;
                  _vm.sort_by_name = true;}}},[_vm._v(" Nominativo "),(_vm.sort_by_name)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_vm._e()],1),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"2"}},[_vm._v(" Username ")]),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"2"}},[_vm._v(" Email ")]),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"}},[_vm._v(" Telefono ")]),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"},on:{"click":function($event){_vm.sort_by_name = false;
                  _vm.sort_by_listino = false;
                  _vm.sort_by_stato = true;}}},[_vm._v(" Stato "),(_vm.sort_by_stato)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_vm._e()],1),_c('v-col',{staticClass:"font-weight-medium",attrs:{"cols":"1"}},[_vm._v(" Ultimo accesso ")])],1),_c('v-divider'),_vm._l((_vm.admins),function(cliente){return [_c('v-lazy',{key:cliente.iva,attrs:{"options":{
                  threshold: 0.5,
                },"min-height":"20"}},[_c('AdminElement',{attrs:{"cliente":cliente}})],1)]})],2)],1)],1)],1)],1),_c('nuovo-cliente')],1)}
var staticRenderFns = []

export { render, staticRenderFns }