<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="grey--text text-subtitle-1"> Utenti </v-card-title>
        <v-card-text>
          <v-tabs>
            <v-tab> Clienti </v-tab>
            <v-tab> Admins</v-tab>
            <v-tab-item>
              <v-row class="my-4">
                <v-col cols="6">
                  <v-text-field
                    v-model="search_string"
                    hide-details
                    outlined
                    label="Cerca ..."
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-2" no-gutters>
                <v-col
                  cols="3"
                  class="font-weight-medium"
                  @click="
                    sort_by_stato = false;
                    sort_by_listino = false;
                    sort_by_name = true;
                  "
                >
                  Nominativo
                  <v-icon v-if="sort_by_name">mdi-chevron-up</v-icon>
                </v-col>
                <v-col cols="1" class="font-weight-medium"> P. Iva </v-col>
                <v-col cols="2" class="font-weight-medium"> Email </v-col>
                <v-col cols="1" class="font-weight-medium"> Telefono </v-col>
                <v-col
                  cols="1"
                  class="font-weight-medium"
                  @click="
                    sort_by_name = false;
                    sort_by_stato = false;
                    sort_by_listino = true;
                  "
                >
                  Listino
                  <v-icon v-if="sort_by_listino">mdi-chevron-up</v-icon>
                </v-col>

                <v-col
                  cols="1"
                  class="font-weight-medium"
                  @click="
                    sort_by_name = false;
                    sort_by_listino = false;
                    sort_by_stato = true;
                  "
                >
                  Stato
                  <v-icon v-if="sort_by_stato">mdi-chevron-up</v-icon>
                </v-col>
                <v-col cols="1" class="font-weight-medium"> Notifiche </v-col>
                <v-col cols="1" class="font-weight-medium">
                  Ultimo accesso
                </v-col>
              </v-row>
              <v-divider />
              <template v-for="cliente in clienti_filtered">
                <v-lazy
                  :key="cliente.iva"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="20"
                >
                  <ClienteElement :cliente="cliente" />
                </v-lazy>
              </template>
            </v-tab-item>
            

            <v-tab-item>
              <v-row class="my-2" no-gutters>
                <v-col
                  cols="2"
                  class="font-weight-medium"
                  @click="
                    sort_by_stato = false;
                    sort_by_listino = false;
                    sort_by_name = true;
                  "
                >
                  Nominativo
                  <v-icon v-if="sort_by_name">mdi-chevron-up</v-icon>
                </v-col>
                <v-col cols="2" class="font-weight-medium"> Username </v-col>
                <v-col cols="2" class="font-weight-medium"> Email </v-col>
                <v-col cols="1" class="font-weight-medium"> Telefono </v-col>

                <v-col
                  cols="1"
                  class="font-weight-medium"
                  @click="
                    sort_by_name = false;
                    sort_by_listino = false;
                    sort_by_stato = true;
                  "
                >
                  Stato
                  <v-icon v-if="sort_by_stato">mdi-chevron-up</v-icon>
                </v-col>
                <v-col cols="1" class="font-weight-medium">
                  Ultimo accesso
                </v-col>
              </v-row>
              <v-divider />
              <template v-for="cliente in admins">
                <v-lazy
                  :key="cliente.iva"
                  :options="{
                    threshold: 0.5,
                  }"
                  min-height="20"
                >
                  <AdminElement :cliente="cliente" />
                </v-lazy>
              </template>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-col>
    <nuovo-cliente />
  </v-row>
</template>

<script>
import utenti_main_mixin from "../mixin/utenti_main_mixin";
import ClienteElement from "./utente_element.vue";
import AdminElement from "./admin_element.vue"
import NuovoCliente from "../nuovo_cliente.vue";
export default {
  mixins: [utenti_main_mixin],
  components: {
    NuovoCliente,
    ClienteElement,
    AdminElement
  },
};
</script>