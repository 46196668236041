<template>
  <div>
    <v-text-field
      v-model="search_string"
      hide-details
      outlined
      label="Cerca ..."
    ></v-text-field>
    <v-list>
      <template v-for="cliente in clienti_filtered">
        <v-lazy
          :key="cliente.iva"
          :options="{
            threshold: 0.5,
          }"
          min-height="20"
        >
          <ClienteElement :key="cliente.iva" :cliente="cliente" />
        </v-lazy>
      </template>
    </v-list>
  </div>
</template>

<script>
import utenti_main_mixin from "../mixin/utenti_main_mixin";
import ClienteElement from "./utente_element_mobile.vue";
export default {
  mixins: [utenti_main_mixin],
  components: {
    ClienteElement,
  },
};
</script>