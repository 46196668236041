<template>
  <ClientiDesktop v-if="!$vuetify.breakpoint.mobile" />
 <ClientiMobile v-else/>
</template>

<script>
import ClientiDesktop from "@/components/Utenti/desktop/utenti_desktop.vue"
import ClientiMobile from "@/components/Utenti/mobile/utenti_mobile.vue"
export default {
  components: {
    ClientiDesktop,
    ClientiMobile
  },
};
</script>