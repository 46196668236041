<template>
  <v-dialog v-model="dialog" width="600" scrollable>
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-content>
          <v-list-item-title> Modifica </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Modifica Cliente
      </v-card-title>
      <v-card-text>
        <v-row class="mt-1">
          <v-col>
            <v-text-field
              hide-details
              prepend-icon="mdi-account"
              outlined
              label="Nominativo"
              v-model="nome"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_nome"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_nome">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_nome">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              prepend-icon="mdi-account"
              outlined
              label="Soprannome"
              v-model="aka"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_aka"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_aka">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_aka">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              prepend-icon="mdi-numeric"
              outlined
              label="Numero"
              v-model="numero"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_numero"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_numero">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_numero">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              prepend-icon="mdi-email"
              outlined
              label="Email"
              v-model="email"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_mail"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_mail">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_mail">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              prepend-icon="mdi-phone"
              outlined
              label="Telefono"
              v-model="telefono"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_tel"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_tel">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_tel">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              prepend-icon="mdi-map-marker"
              outlined
              label="Indirizzo di Consegna"
              v-model="indirizzo"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_indirizzo"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_indirizzo">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_indirizzo">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              prepend-icon="mdi-city"
              outlined
              label="Città"
              v-model="città"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_città"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_città">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_città">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              hide-details
              prepend-icon="mdi-city"
              outlined
              label="Destinazioni"
              :items="destinazioni"
            >
              <template v-slot:item="{ item }">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.indirizzo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{item.plus_code}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ item.primaria ? "Sede Principale" : "Sede Scarico" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="del_dest(item.id.$oid)"
                      v-if="!item.primaria"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-list-item-action>
                    <v-dialog width="400" v-model="dialog_edit_pluscode">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on"
                          ><v-icon>mdi-google-maps</v-icon></v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-title class="grey--text text-subtitle-1"
                          >Aggiungi Plus Code</v-card-title
                        >
                        <v-card-text>
                          <v-text-field
                            hide-details
                            outlined
                            label="Plus Code"
                            v-model="plus_code"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn outlined color="green" :loading="loading_plus" @click="edit_plus_code()">OK</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <template v-slot:append-item>
                <v-dialog v-model="dialog_add_dest" width="500">
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                      <v-list-item-avatar>
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title> Aggiungi ... </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title class="grey--text"
                      >Nuova Destinazione</v-card-title
                    >
                    <v-card-text>
                      <v-form v-model="new_dest_valid" ref="new_dest_form">
                        <v-text-field
                          label="Indirizzo"
                          v-model="nuova_dest"
                          outlined
                          prepend-icon="mdi-map-marker"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field
                          v-model="plus_code"
                          label="Plus Code"
                          outlined
                          prepend-icon="mdi-earth-plus"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn outlined color="green" @click="add_dest">ok</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="1">
            <v-icon>mdi-cart</v-icon>
          </v-col>
          <v-col cols="11">
            <v-switch
              class="mt-n1"
              hide-details
              inset
              label="Cambio Carrelli"
              :loading="loading_cambio"
              v-model="cambio_carrello"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="6">
            <v-select
              hide-details
              prepend-icon="mdi-cart"
              outlined
              label="Tipo di Carrello"
              v-model="tipo_carrello"
              :items="lista_tipo_cc"
              item-text="text"
              item-value="value"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_tipo_cc"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_tipo_cc">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_tipo_cc">mdi-error</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              hide-details
              prepend-icon="mdi-account-cash"
              outlined
              label="Listino"
              v-model="listino"
              :items="['1', '2', '3']"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_listino"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_listino">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_listino">mdi-error</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              hide-details
              prepend-icon="mdi-truck"
              outlined
              label="Zona Consegna"
              v-model="zona_consegna"
              :items="zone"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_zona"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_zona">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_zona">mdi-error</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined color="green" @click="dialog = false">Fatto</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import modifica_utente_mixin from "../mixin/utente_modifica_mixin";
export default {
  mixins: [modifica_utente_mixin],
};
</script>